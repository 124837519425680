.navbar {
  background-color: #111 !important;
  padding: 1px;
}

.navbar-toolbar {
  align-items: center;
  justify-content: space-between;
}

.navbar-title {
  text-align: left;
  color: ivory;
}

.navbar-item {
  color: ivory !important; 
  margin-right: 15px !important; 
}

.navbar-item:hover {
  color: royalblue !important; 
}

.wallet-address {
  border: 1px solid royalblue !important;
}
