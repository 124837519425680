body {
  background: linear-gradient(to top, #0D0D54, #000);
  margin: 0;
  color: ivory;
  font-family: Arial, sans-serif;
}

.gallery-container {
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
}

.gallery-container::-webkit-scrollbar {
  width: 12px;
}

.gallery-container::-webkit-scrollbar-thumb {
  background-color: darkgray; 
  border-radius: 6px;
}

.gallery-container::-webkit-scrollbar-track {
  background-color: #333; 
}

.tab-button {
  padding: 5px 5px !important;
  background-color: rgba(51, 51, 51, 0.3) !important;
  color: ivory !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
  transition: all 0.2s ease-in-out !important;
}

.tab-button:hover {
  background-color: rgba(51, 51, 51, 0.5) !important;
}

.selected-tab {
  color: royalblue !important;
  background-color: rgba(51, 51, 51, 0.7) !important;
  border-bottom: 3px solid royalblue !important;
}